.image-preview {
    position: relative;
    overflow: hidden;
    transition: height 300ms ease;
}

.image-preview .blurred {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(5px);
    opacity: 0.7;
    height: 100%;
}

.image-preview .inner {
    transform: translate3d(0,0,0);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}