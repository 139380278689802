.wishlist__description>p:empty {
    display: none;
}

.bought-popup .controls {
    font-size: 36pt;
    font-weight: 500;
    text-align: center;
    margin: 25vh 0;
    display: flex;
    justify-content: center;
}

.bought-popup .controls>.amount {
    min-width: 64px;
    display: inline-block;
}

.wish-item {
    cursor: pointer;
}

.wish-item ion-avatar {
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-light);
    text-align: center;
    font-size: 20px;
    line-height: 40px;
}

.wish-item h2.bought {
    text-decoration: line-through;
}

.wish-item h2 a {
    position: relative;
    padding-right: 20px;
    text-decoration: none;
}

.wish-item h2 ion-icon {
    position: absolute;
    right: 0;
    top: 0;
}

.wish-item .price-1 > ion-icon:nth-child(2) {
    opacity: 0.3;
}
.wish-item .price-1 > ion-icon:nth-child(3) {
    opacity: 0.3;
}

.wish-item .price-2 > ion-icon:nth-child(3) {
    opacity: 0.3;
}

.wish-group .counter {
    position: fixed;
    font-size: 10px;
    top: 0;
    right: 2px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    line-height: 15px;
    background: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
}

.wish__expand_image {
    position: absolute;
    top:8px;
    right: 8px;
    --background-activated: rgba(0,0,0,0.4);
    --background: rgba(0,0,0,0.5);
}

.wishlist.draft {
    --background: #fffbf2;
    --ion-color-light: #e0dcd3;
    --ion-item-background: #fffbf2;
}