.login {
    text-align: center;
    --padding-top: 10vh;
}

.login .content {
    width: 300px;
    margin: 20px auto;
    max-width: 80vw;
}

.login .email-login {
    background: rgba(255,255,255,0.7);
    padding: 16px;
}

.login .email-login ion-item {
    --background: transparent;
}

.login h1 {
    font-size: 3.75rem;
    letter-spacing: -0.00833em;
    font-weight: 300;
    line-height: 1;
    margin: 0;
}

.login h2 {
    font-size: 1.5rem;
    line-height: 1.33;
    letter-spacing: 0;
    margin: 0;
}

.login .with.google {
    --background: #ffffff;
    --background-activated: #ffffff;
    --background-focused: #eeeeee;
    --color: rgba(0, 0, 0, 0.54);
    --color-focused: rgba(0, 0, 0, 0.54);
    --color-activated: rgba(0, 0, 0, 0.54);
}
.login .with.google>div {
    background-image: url('./icons/google.svg');
}

.login .with.facebook {
    --background: #3B5998;
    --background-focused: #4C6AA9;
}
.login .with.facebook>div {
    background-image: url('./icons/facebook.svg');
}

.login .with.email>div {
    background-image: url('./icons/email.svg');
}

.login .with.incognito {
    --background: #323639;
    --background-focused: #43474A;
}
.login .with.incognito>div {
    background-image: url('./icons/incognito.svg');
}

.login .with.microsoft {
    --background: #2f2f2f;
    --background-focused: #3f3f3f;
    --color: #ffffff;
}
.login .with.microsoft>div {
    background-image: url('./icons/microsoft.svg');
}

.login .with {
    --border-radius: 0;
    --padding-start: 0;
    --padding-end: 0;
    text-transform: none;
    
    margin: 16px 0;
    height: 42px;
}

.login .with>div {
    text-align: left;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.02857em;

    padding-left: 50px;
    background-size: 24px;
    background-position: 8px;
    background-repeat: no-repeat;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login .links {
    margin-top: 10vh;
}

.login .links>a {
    color: inherit;
    padding: 8px;
    font-size: 0.875rem;
    letter-spacing: 0.01071em;
}