.add-wish {
    display: flex;
    position: relative;
    background-color: var(--ion-color-primary-tint);
    padding-left: 8px;
    padding-right: 8px;
}

.add-wish>ion-input.category {
    width: 80px;
    flex: none;
}

.add-wish>ion-input {
    --color: rgba(var(--ion-color-primary-contrast-rgb), 0.9);
}

.add-wish .suggestions {
    position: absolute;
    top: calc(100% + 8px);
    box-shadow:
        0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.add-wish:not(:focus-within) .category {
    display: none;
}

.add-wish:not(:focus-within) .suggestions {
    display: none;
}
